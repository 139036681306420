<template>
    <div>
       <skupovi-navigation
                :id="skup_id"
                :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox ">
            <div class="ibox-title">
                <h5>Kotizacija</h5><br>
                <button
                        type="button"
                        class="btn btn-lg btn-primary"
                        @click.prevent="create"
                >
                    Novi unos
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                        v-if="show_datatable"
                        :url="datatables_url"
                        :settings="datatables.settings"
                        :title="datatables.title"
                        @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "@/components/datatable/Datatables";
import SkupoviNavigation from "@/views/pages/Skupovi/SkupoviNavigation";

export default {
    name: "SkupoviKotiacije",
    components: {
        Datatables,
        SkupoviNavigation
    },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'skup-kotizacija',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/kotizacije/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/kotizacije/create' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.skup_id
                            },
                        },
                        {
                            'id': 'vrsta_sudionika_naziv',
                            'title': 'Vrsta sudionika',
                            'type' : function ( row ){
                                return row.vrste_sudionika ? row.vrste_sudionika.naziv : ''
                            },
                            'searchable' : true,
                            'column_visibility_group': {
                                id: 'kotizacija',
                            }
                        },
                        {
                            'id': 'kotizacije.naziv',
                            'title': 'Kotizacija',
                            'searchable' : false,
                            'column_visibility_group': {
                                id: 'kotizacija',
                            }
                        },
                        {
                            'id': 'kotizacije.opis',
                            'title': 'Kotizacija - opis',
                            'searchable' : false,
                            'column_visibility_group': {
                                id: 'kotizacija',
                            }
                        },
                        {
                            'id': 'cijena',
                            'title': 'Cijena',
                            'searchable' : false,
                            'column_visibility_group': {
                                id: 'kotizacija',
                            }
                        },
                        {
                            'id': 'porezi.postotak',
                            'searchable' : false,
                            'title': 'Porez',
                            'column_visibility_group': {
                                id: 'kotizacija',
                            }
                        },

                    ],
                    column_visibility_groups:{
                        'kotizacija' : 'Kotizacija'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Kotizacija',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/kotizacije',
                    name: 'Kotizacija'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
