<template>
    <div>

        <breadcrumbs
                :title="'Događaji'"
                :breadcrumbs="allBreadcrumbs"
        >
        </breadcrumbs>

        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #2F4050;">
            <a
                    :class="{
                            'navbar-brand btn btn-light btn-xl': true,
                            'active': active_route === route
                        }"
                    @click="open(route)"
            >
                <strong>Skup</strong>
            </a>

            <div v-if="skup" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li v-if="skup.vrsta_skupova.prijava_forma && skup.kotizacija_skupa" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/kotizacije'
                                    }"
                                @click.prevent="open(route + '/kotizacije')"
                        >
                            <strong>Kotizacija</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.prijava_forma && !skup.vrsta_skupova.webinar && skup.kotizacija_skupa && komora === 'HKIG'" class="nav-item">
                        <a
                            role="button"
                            :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/suorganizatori'
                                    }"
                            @click.prevent="open(route + '/suorganizatori')"
                        >
                            <strong>Suorganizatori</strong>
                        </a>
                    </li>
                    <!--<li v-if="skup.vrsta_skupova.prijava_forma && !skup.vrsta_skupova.webinar" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/dodatni-sadrzaji'
                                    }"
                                @click.prevent="open(route + '/dodatni-sadrzaji')"
                        >
                            <strong>Dodatni sadržaj</strong>
                        </a>
                    </li>-->
                    <li v-if="skup.vrsta_skupova.prijava_forma" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/teme'
                                    }"
                                @click.prevent="open(route + '/teme')"
                        >
                            <strong>Teme</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.prijava_forma" class="nav-item">
                        <a
                            role="button"
                            :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/podteme'
                                    }"
                            @click.prevent="open(route + '/podteme')"
                        >
                            <strong>Podteme</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.webinar" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/webinari'
                                    }"
                                @click.prevent="open(route + '/webinari')"
                        >
                            <strong>Webinari</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.prijava_forma" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/prijave'
                                    }"
                                @click.prevent="open( route + '/prijave')"
                        >
                            <strong>Prijave</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.prilagodjena_forma" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/forma-data'
                                    }"
                                @click.prevent="open( route + '/forma-data')"
                        >
                            <strong>Sudionici</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.vanjska_poveznica" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/poveznica-data'
                                    }"
                                @click.prevent="open( route + '/poveznica-data')"
                        >
                            <strong>Sudionici</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.prijava_forma" class="nav-item">
                        <a
                                role="button"
                                :class="{
                                        'nav-link btn btn-light' : true,
                                        'active': active_route === route + '/ispisi'
                                    }"
                                @click.prevent="open( route + '/ispisi')"
                        >
                            <strong>Ispisi</strong>
                        </a>
                    </li>
                    <li v-if="skup.vrsta_skupova.prijava_forma && !skup.vrsta_skupova.webinar && komora === 'HKIG' " class="nav-item">
                        <a
                                role="button"
                                :class="{
                                            'nav-link btn btn-light' : true,
                                            'active': active_route === route + '/dvorane'
                                        }"
                                @click.prevent="open( route + '/dvorane')"
                        >
                            <strong>Dvorane</strong>
                        </a>
                    </li>
<!--                    <li v-if="skup.vrsta_skupova.prijava_forma && !skup.vrsta_skupova.webinar && komora === 'HKIG' " class="nav-item">-->
<!--                        <a-->
<!--                                role="button"-->
<!--                                :class="{-->
<!--                                            'nav-link btn btn-light' : true,-->
<!--                                            'active': active_route === route + '/qrs'-->
<!--                                        }"-->
<!--                                @click.prevent="open( route + '/qrs')"-->
<!--                        >-->
<!--                            <strong>QRs</strong>-->
<!--                        </a>-->
<!--                    </li>-->
                    <li v-if="skup.vrsta_skupova.prijava_forma && !skup.vrsta_skupova.webinar && komora === 'HKIG' " class="nav-item">
                        <a
                                role="button"
                                :class="{
                                            'nav-link btn btn-light' : true,
                                            'active': active_route === route + '/sudionici'
                                        }"
                                @click.prevent="open( route + '/sudionici')"
                        >
                            <strong>Registracija sudionika</strong>
                        </a>
                    </li>
<!--                    <li v-if="skup.vrsta_skupova.prijava_forma && !skup.promet " class="nav-item">-->
<!--                        <a-->
<!--                                role="button"-->
<!--                                :class="{-->
<!--                                            'nav-link btn btn-light' : true,-->
<!--                                            'active': active_route === route + '/promet'-->
<!--                                        }"-->
<!--                                @click.prevent="open( route + '/promet')"-->
<!--                        >-->
<!--                            <strong>Promet</strong>-->
<!--                        </a>-->
<!--                    </li>-->

                    <li v-if="skup.vrsta_skupova.prijava_forma && komora === 'HKIG' " class="nav-item">
                        <a
                            role="button"
                            :class="{
                                            'nav-link btn btn-light' : true,
                                            'active': active_route === route + '/emails'
                                        }"
                            @click.prevent="open( route + '/emails')"
                        >
                            <strong>Obavijesti</strong>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>


    </div>

</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import axios from "axios";

export default {
    name: "SkupoviNavigation",
    components: {Breadcrumbs},
    props:[
            'id',
            'breadcrumbs'
    ],
    data(){
        return {
            komora: process.env.VUE_APP_APP_OWNER,
            skup : null,
            naziv: '',
            route: '/skupovi/' + this.id,
            active_route: '',
            allBreadcrumbs: []
        }
    },
    methods:{
        open( name ){
            if( name !== this.active_route ){
                this.active_route = name
                this.$router.push( name )
            }
        },
        getSkup(){
            let vm = this
            axios.get( process.env.VUE_APP_API_URL + 'skup' + '/' + vm.id )
                    .then( function ( response ) {
                        vm.skup = response.data
                        vm.setBreadcrumbs()
                    } )
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },
        setBreadcrumbs(){
            let vm = this

            vm.allBreadcrumbs = [
                {
                    path: '/skupovi',
                    name: 'Događaji'
                },
                {
                    path: ( '/skupovi/' + this.id ),
                    name: ( 'Događaj: ' + this.skup.naziv )
                },
            ]

            vm.breadcrumbs.forEach( function( b ){
                vm.allBreadcrumbs.push( b )
            } )
        }
    },
    mounted() {
        this.active_route = this.$route.path
        this.getSkup()
    },
    watch:{
        breadcrumbs: {
            deep: true,
            handler: function(){
                this.setBreadcrumbs()
            }
        },
    },
}
</script>

<style scoped>
a{
    margin-left: 3px;
}
</style>
