import Vue from "vue";
import VueRouter from "vue-router";



import Login from "../views/auth/Login.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import Logout from "@/views/auth/Logout";

import AdministratorBase from "@/views/pages/Administratori/AdministratorBase";
import Administratori from "@/views/pages/Administratori/Administratori";
import AdministratorDetails from "@/views/pages/Administratori/AdministratorDetails";
import AdministratorEdit from "@/views/pages/Administratori/AdministratorEdit";
import AdministratorCreate from "@/views/pages/Administratori/AdministratorCreate";

import Korisnici from "@/views/pages/Korisnici/Korisnici";
import KorisnikBase from "@/views/pages/Korisnici/KorisnikBase";
import KorisnikDetails from "@/views/pages/Korisnici/KorisnikDetails";
import KorisnikEdit from "@/views/pages/Korisnici/KorisnikEdit";
import KorisnikCreate from "@/views/pages/Korisnici/KorisnikCreate";

import DodatniSadrzajBase from "@/views/pages/Postavke/DodatniSadrzaji/DodatniSadrzajBase";
import DodatniSadrzaji from "@/views/pages/Postavke/DodatniSadrzaji/DodatniSadrzaji";
import DodatniSadrzajDetails from "@/views/pages/Postavke/DodatniSadrzaji/DodatniSadrzajDetails";
import DodatniSadrzajEdit from "@/views/pages/Postavke/DodatniSadrzaji/DodatniSadrzajEdit";
import DodatniSadrzajCreate from "@/views/pages/Postavke/DodatniSadrzaji/DodatniSadrzajCreate";

import DvoranaCreate from "@/views/pages/Skupovi/Dvorane/DvoranaCreate";
import DvoranaBase from "@/views/pages/Skupovi/Dvorane/DvoranaBase";
import Dvorane from "@/views/pages/Skupovi/Dvorane/Dvorane";
import DvoranaDetails from "@/views/pages/Skupovi/Dvorane/DvoranaDetails";
import DvoranaEdit from "@/views/pages/Skupovi/Dvorane/DvoranaEdit";

import RacunZaUplatuBase from "@/views/pages/Postavke/RacuniZaUplate/RacunZaUplatuBase";
import RacuniZaUplate from "@/views/pages/Postavke/RacuniZaUplate/RacuniZaUplate";
import RacunZaUplatuDetails from "@/views/pages/Postavke/RacuniZaUplate/RacunZaUplatuDetails";
import RacunZaUplatuEdit from "@/views/pages/Postavke/RacuniZaUplate/RacunZaUplatuEdit";
import RacunZaUplatuCreate from "@/views/pages/Postavke/RacuniZaUplate/RacunZaUplatuCreate";

import VrstaSudionikaBase from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaBase";
import VrsteSudionika from "../views/pages/Postavke/VrsteSudionika/VrsteSudionika";
import VrstaSudionikaDetails from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaDetails";
import VrstaSudionikaEdit from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaEdit";
import VrstaSudionikaCreate from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaCreate";

import VrstaSuorganizatoraBase from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraBase";
import VrsteSuorganizatora from "../views/pages/Postavke/VrsteSuorganizatora/VrsteSuorganizatora";
import VrstaSuorganizatoraDetails from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraDetails";
import VrstaSuorganizatoraEdit from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraEdit";
import VrstaSuorganizatoraCreate from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraCreate";

import SkupBase from "@/views/pages/Skupovi/SkupBase";
import Skupovi from "@/views/pages/Skupovi/Skupovi";
import SkupDetails from "@/views/pages/Skupovi/SkupDetails";
import SkupEdit from "@/views/pages/Skupovi/SkupEdit";
import SkupCreate from "@/views/pages/Skupovi/SkupCreate";

import PrijavaBase from "@/views/pages/Skupovi/Prijave/PrijavaBase";
import Prijave from "@/views/pages/Skupovi/Prijave/Prijave";
import PrijavaDetails from "@/views/pages/Skupovi/Prijave/PrijavaDetails";
import PrijavaEdit from "@/views/pages/Skupovi/Prijave/PrijavaEdit";
import PrijavaCreate from "@/views/pages/Skupovi/Prijave/PrijavaCreate";

import SkupDodatniSadrzajBase from "@/views/pages/Skupovi/SkupoviDodatniSadrzaji/SkupDodatniSadrzajBase";
import SkupoviDodatniSadrzaji from "@/views/pages/Skupovi/SkupoviDodatniSadrzaji/SkupoviDodatniSadrzaji";
import SkupDodatniSadrzajDetails from "@/views/pages/Skupovi/SkupoviDodatniSadrzaji/SkupDodatniSadrzajDetails";
import SkupDodatniSadrzajEdit from "@/views/pages/Skupovi/SkupoviDodatniSadrzaji/SkupDodatniSadrzajEdit";
import SkupDodatniSadrzajCreate from "@/views/pages/Skupovi/SkupoviDodatniSadrzaji/SkupDodatniSadrzajCreate";

import SkupKotizacijaBase from "@/views/pages/Skupovi/SkupoviKotizacije/SkupKotizacijaBase";
import SkupoviKotizacije from "@/views/pages/Skupovi/SkupoviKotizacije/SkupoviKotizacije";
import SkupKotizacijaDetails from "@/views/pages/Skupovi/SkupoviKotizacije/SkupKotizacijaDetails";
import SkupKotizacijaEdit from "@/views/pages/Skupovi/SkupoviKotizacije/SkupKotizacijaEdit";
import SkupKotizacijaCreate from "@/views/pages/Skupovi/SkupoviKotizacije/SkupKotizacijaCreate";

import SkupSuorganizatorBase from "../views/pages/Skupovi/SkupoviSuorganizatori/SkupSuorganizatorBase";
import SkupoviSuorganizatori from "../views/pages/Skupovi/SkupoviSuorganizatori/SkupoviSuorganizatori";
import SkupSuorganizatorDetails from "../views/pages/Skupovi/SkupoviSuorganizatori/SkupSuorganizatorDetails";
import SkupSuorganizatorEdit from "../views/pages/Skupovi/SkupoviSuorganizatori/SkupSuorganizatorEdit";
import SkupSuorganizatorCreate from "../views/pages/Skupovi/SkupoviSuorganizatori/SkupSuorganizatorCreate";

import SkupTemaBase from "@/views/pages/Skupovi/SkupoviTeme/SkupTemaBase";
import SkupoviTeme from "@/views/pages/Skupovi/SkupoviTeme/SkupoviTeme";
import SkupTemaDetails from "@/views/pages/Skupovi/SkupoviTeme/SkupTemaDetails";
import SkupTemaEdit from "@/views/pages/Skupovi/SkupoviTeme/SkupTemaEdit";
import SkupTemaCreate from "@/views/pages/Skupovi/SkupoviTeme/SkupTemaCreate";

import SkupTema2Base from "@/views/pages/Skupovi/SkupoviTeme2/SkupTema2Base";
import SkupoviTeme2 from "@/views/pages/Skupovi/SkupoviTeme2/SkupoviTeme2";
import SkupTema2Details from "@/views/pages/Skupovi/SkupoviTeme2/SkupTema2Details";
import SkupTema2Edit from "@/views/pages/Skupovi/SkupoviTeme2/SkupTema2Edit";
import SkupTema2Create from "@/views/pages/Skupovi/SkupoviTeme2/SkupTema2Create";

import WebinarBase from "@/views/pages/Skupovi/Webinars/WebinarBase";
import Webinars from "@/views/pages/Skupovi/Webinars/Webinars";
import WebinarDetails from "@/views/pages/Skupovi/Webinars/WebinarDetails";
import WebinarEdit from "@/views/pages/Skupovi/Webinars/WebinarEdit";
import WebinarCreate from "@/views/pages/Skupovi/Webinars/WebinarCreate";

import FormaDataCreate from "@/views/pages/Skupovi/FormeData/FormaDataCreate";
import FormaDataBase from "@/views/pages/Skupovi/FormeData/FormaDataBase";
import FormaData from "@/views/pages/Skupovi/FormeData/FormaData";
import FormaDataDetails from "@/views/pages/Skupovi/FormeData/FormaDataDetails";
import FormaDataEdit from "@/views/pages/Skupovi/FormeData/FormaDataEdit";

import PoveznicaDataCreate from "@/views/pages/Skupovi/PovezniceData/PoveznicaDataCreate";
import PoveznicaDataBase from "@/views/pages/Skupovi/PovezniceData/PoveznicaDataBase";
import PoveznicaData from "@/views/pages/Skupovi/PovezniceData/PoveznicaData";
import PoveznicaDataDetails from "@/views/pages/Skupovi/PovezniceData/PoveznicaDataDetails";
import PoveznicaDataEdit from "@/views/pages/Skupovi/PovezniceData/PoveznicaDataEdit";

import EmailBase from "@/views/pages/Skupovi/Emails/EmailBase";
import EmailCreate from "@/views/pages/Skupovi/Emails/EmailCreate";
import EmailDetails from "@/views/pages/Skupovi/Emails/EmailDetails";
import EmailEdit from "@/views/pages/Skupovi/Emails/EmailEdit";
import Emails from "@/views/pages/Skupovi/Emails/Emails";
import Emailing from "@/views/pages/Skupovi/Emails/Emailing";

import QrBase from "@/views/pages/Skupovi/QR/QrBase";
import Qrs from "@/views/pages/Skupovi/QR/Qrs";

import RegistracijaSudionikaBase from "@/views/pages/Skupovi/RegistracijaSudionika/RegistracijaSudionikaBase";
import RegistracijaSudionika from "@/views/pages/Skupovi/RegistracijaSudionika/RegistracijaSudionika";

import PrometCreate from "@/views/pages/Skupovi/Promet/PrometCreate";
import Promet from "@/views/pages/Skupovi/Promet/Promet";
import PrometBase from "@/views/pages/Skupovi/Promet/PrometBase";
import PrometDetails from "@/views/pages/Skupovi/Promet/PrometDetails";
import PrometEdit from "@/views/pages/Skupovi/Promet/PrometEdit";

import Home from "@/views/layout/Home";
import Ispisi from "@/views/pages/Skupovi/Ispisi/Ispisi";



Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/login");
  }
};

const routes = [

  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: Login
  },
  {
    path: "/forgot-password",
    beforeEnter: guest,
    component: ForgotPassword
  },
  {
    path: "/reset-password/:token",
    beforeEnter: guest,
    component: ResetPassword
  },
  {
    path: "/logout",
    //beforeEnter: auth,
    component: Logout
  },
  {
    path: "/",
    beforeEnter: auth,
    component: Home,
    children: [
      {
        path: "/",
        beforeEnter: auth,
        component: Skupovi
      },
      {
        path: '/administratori/create',
        beforeEnter: auth,
        component: AdministratorCreate,
      },
      {
        path: "/administratori",
        beforeEnter: auth,
        component: AdministratorBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Administratori,
          },
          {
            path: ':admin_id',
            beforeEnter: auth,
            component: AdministratorDetails,
          },
          {
            path: ':admin_id/edit',
            beforeEnter: auth,
            component: AdministratorEdit,
          },
        ]
      },
      {
        path: '/korisnici/create',
        beforeEnter: auth,
        component: KorisnikCreate,
      },
      {
        path: "/korisnici",
        beforeEnter: auth,
        component: KorisnikBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Korisnici,
          },
          {
            path: ':korisnik_id',
            beforeEnter: auth,
            component: KorisnikDetails,
          },
          {
            path: ':korisnik_id/edit',
            beforeEnter: auth,
            component: KorisnikEdit,
          },
        ]
      },
      {
        path: '/postavke/dodatni-sadrzaji/create',
        beforeEnter: auth,
        component: DodatniSadrzajCreate,
      },
      {
        path: "/postavke/dodatni-sadrzaji",
        beforeEnter: auth,
        component: DodatniSadrzajBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: DodatniSadrzaji,
          },
          {
            path: ':dodatni_sadrzaj_id',
            beforeEnter: auth,
            component: DodatniSadrzajDetails,
          },
          {
            path: ':dodatni_sadrzaj_id/edit',
            beforeEnter: auth,
            component: DodatniSadrzajEdit,
          },
        ]
      },
      {
        path: '/postavke/racuni-za-uplate/create',
        beforeEnter: auth,
        component: RacunZaUplatuCreate,
      },
      {
        path: "/postavke/racuni-za-uplate",
        beforeEnter: auth,
        component: RacunZaUplatuBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: RacuniZaUplate,
          },
          {
            path: ':racun_za_uplatu_id',
            beforeEnter: auth,
            component: RacunZaUplatuDetails,
          },
          {
            path: ':racun_za_uplatu_id/edit',
            beforeEnter: auth,
            component: RacunZaUplatuEdit,
          },
        ]
      },
      {
        path: '/postavke/vrste-sudionika/create',
        beforeEnter: auth,
        component: VrstaSudionikaCreate,
      },
      {
        path: "/postavke/vrste-sudionika",
        beforeEnter: auth,
        component: VrstaSudionikaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrsteSudionika,
          },
          {
            path: ':vrsta_sudionika_id',
            beforeEnter: auth,
            component: VrstaSudionikaDetails,
          },
          {
            path: ':vrsta_sudionika_id/edit',
            beforeEnter: auth,
            component: VrstaSudionikaEdit,
          },
        ]
      },
      {
        path: '/postavke/vrste-suorganizatora/create',
        beforeEnter: auth,
        component: VrstaSuorganizatoraCreate,
      },
      {
        path: "/postavke/vrste-suorganizatora",
        beforeEnter: auth,
        component: VrstaSuorganizatoraBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrsteSuorganizatora,
          },
          {
            path: ':vrsta_suorganizatora_id',
            beforeEnter: auth,
            component: VrstaSuorganizatoraDetails,
          },
          {
            path: ':vrsta_suorganizatora_id/edit',
            beforeEnter: auth,
            component: VrstaSuorganizatoraEdit,
          },
        ]
      },
      {
        path: '/skupovi/create',
        beforeEnter: auth,
        component: SkupCreate,
      },
      {
        path: "/skupovi",
        beforeEnter: auth,
        component: SkupBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Skupovi,
          },
          {
            path: ':skup_id',
            beforeEnter: auth,
            component: SkupDetails,
          },
          {
            path: ':skup_id/prijave/create',
            beforeEnter: auth,
            component: PrijavaCreate,
          },
          {
            path: ':skup_id/prijave',
            beforeEnter: auth,
            component: PrijavaBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: Prijave,
              },
              {
                path: ':prijava_id',
                beforeEnter: auth,
                component: PrijavaDetails,
              },
              {
                path: ':prijava_id/edit',
                beforeEnter: auth,
                component: PrijavaEdit,
              },
            ]
          },
          {
            path: ':skup_id/forma-data/create',
            beforeEnter: auth,
            component: FormaDataCreate,
          },
          {
            path: ':skup_id/forma-data',
            beforeEnter: auth,
            component: FormaDataBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: FormaData,
              },
              {
                path: ':forma_data_id',
                beforeEnter: auth,
                component: FormaDataDetails,
              },
              {
                path: ':forma_data_id/edit',
                beforeEnter: auth,
                component: FormaDataEdit,
              },
            ]
          },
          {
            path: ':skup_id/poveznica-data/create',
            beforeEnter: auth,
            component: PoveznicaDataCreate,
          },
          {
            path: ':skup_id/poveznica-data',
            beforeEnter: auth,
            component: PoveznicaDataBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: PoveznicaData,
              },
              {
                path: ':poveznica_data_id',
                beforeEnter: auth,
                component: PoveznicaDataDetails,
              },
              {
                path: ':poveznica_data_id/edit',
                beforeEnter: auth,
                component: PoveznicaDataEdit,
              },
            ]
          },
          {
            path: ':skup_id/dodatni-sadrzaji/create',
            beforeEnter: auth,
            component: SkupDodatniSadrzajCreate,
          },
          {
            path: ':skup_id/dodatni-sadrzaji',
            beforeEnter: auth,
            component: SkupDodatniSadrzajBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: SkupoviDodatniSadrzaji,
              },
              {
                path: ':dodatni_sadrzaj_id',
                beforeEnter: auth,
                component: SkupDodatniSadrzajDetails,
              },
              {
                path: ':dodatni_sadrzaj_id/edit',
                beforeEnter: auth,
                component: SkupDodatniSadrzajEdit,
              },
            ]
          },
          {
            path: ':skup_id/kotizacije/create',
            beforeEnter: auth,
            component: SkupKotizacijaCreate,
          },
          {
            path: ':skup_id/kotizacije',
            beforeEnter: auth,
            component: SkupKotizacijaBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: SkupoviKotizacije,
              },
              {
                path: ':kotizacija_id',
                beforeEnter: auth,
                component: SkupKotizacijaDetails,
              },
              {
                path: ':kotizacija_id/edit',
                beforeEnter: auth,
                component: SkupKotizacijaEdit,
              },
            ]
          },
          {
            path: ':skup_id/suorganizatori/create',
            beforeEnter: auth,
            component: SkupSuorganizatorCreate,
          },
          {
            path: ':skup_id/suorganizatori',
            beforeEnter: auth,
            component: SkupSuorganizatorBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: SkupoviSuorganizatori,
              },
              {
                path: ':suorganizator_id',
                beforeEnter: auth,
                component: SkupSuorganizatorDetails,
              },
              {
                path: ':suorganizator_id/edit',
                beforeEnter: auth,
                component: SkupSuorganizatorEdit,
              },
            ]
          },
          {
            path: ':skup_id/teme/create',
            beforeEnter: auth,
            component: SkupTemaCreate,
          },
          {
            path: ':skup_id/teme',
            beforeEnter: auth,
            component: SkupTemaBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: SkupoviTeme,
              },
              {
                path: ':tema_id',
                beforeEnter: auth,
                component: SkupTemaDetails,
              },
              {
                path: ':tema_id/edit',
                beforeEnter: auth,
                component: SkupTemaEdit,
              },
            ]
          },
          {
            path: ':skup_id/podteme/create',
            beforeEnter: auth,
            component: SkupTema2Create,
          },
          {
            path: ':skup_id/podteme',
            beforeEnter: auth,
            component: SkupTema2Base,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: SkupoviTeme2,
              },
              {
                path: ':tema2_id',
                beforeEnter: auth,
                component: SkupTema2Details,
              },
              {
                path: ':tema2_id/edit',
                beforeEnter: auth,
                component: SkupTema2Edit,
              },
            ]
          },
          {
            path: ':skup_id/webinari/create',
            beforeEnter: auth,
            component: WebinarCreate,
          },
          {
            path: ':skup_id/webinari',
            beforeEnter: auth,
            component: WebinarBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: Webinars,
              },
              {
                path: ':webinar_id',
                beforeEnter: auth,
                component: WebinarDetails,
              },
              {
                path: ':webinar_id/edit',
                beforeEnter: auth,
                component: WebinarEdit,
              },
            ]
          },
          {
            path: ':skup_id/dvorane/create',
            beforeEnter: auth,
            component: DvoranaCreate,
          },
          {
            path: ':skup_id/dvorane',
            beforeEnter: auth,
            component: DvoranaBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: Dvorane,
              },
              {
                path: ':dvorana_id',
                beforeEnter: auth,
                component: DvoranaDetails,
              },
              {
                path: ':dvorana_id/edit',
                beforeEnter: auth,
                component: DvoranaEdit,
              },
            ]
          },
          {
            path: ':skup_id/sudionici',
            beforeEnter: auth,
            component: RegistracijaSudionikaBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: RegistracijaSudionika
              }
            ]
          },
          {
            path: ':skup_id/promet/create',
            beforeEnter: auth,
            component: PrometCreate,
          },
          {
            path: ':skup_id/promet',
            beforeEnter: auth,
            component: PrometBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: Promet,
              },
              {
                path: ':promet_id',
                beforeEnter: auth,
                component: PrometDetails,
              },
              {
                path: ':promet_id/edit',
                beforeEnter: auth,
                component: PrometEdit,
              },
            ]
          },
          {
            path: ':skup_id/qrs',
            beforeEnter: auth,
            component: QrBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: Qrs
              }
            ]
          },
          {
            path: ':skup_id/ispisi',
            beforeEnter: auth,
            component: Ispisi
          },
          {
            path: ':skup_id/edit',
            beforeEnter: auth,
            component: SkupEdit,
          },
          {
            path: ':skup_id/emails/create',
            beforeEnter: auth,
            component: EmailCreate,
          },
          {
            path: ':skup_id/emails',
            beforeEnter: auth,
            component: EmailBase,
            children: [
              {
                path: '',
                beforeEnter: auth,
                component: Emails,
              },
              {
                path: ':email_id',
                beforeEnter: auth,
                component: EmailDetails,
              },
              {
                path: ':email_id/edit',
                beforeEnter: auth,
                component: EmailEdit,
              },
              {
                path: ':email_id/emailing',
                beforeEnter: auth,
                component: Emailing,
              },
            ]
          },

        ]
      },
    ]
  },






];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
