<template>

    <nav class="navbar-default navbar-static-side" role="navigation">
        <div class="sidebar-collapse">
            <ul class="nav metismenu" id="side-menu">
                <li class="nav-header">
                    <div class="dropdown profile-element">
                        <span class="block m-t-xs font-bold" style="color: white"> {{ app_name ? app_name : '' }}</span>
                        <span class="block m-t-xs font-bold"> {{ app_owner ? app_owner : '' }}</span>
                    </div>
                    <div class="logo-element">
                        {{ app_name ? app_name : '' }}
                    </div>
                </li>

                <!--<li @click="change_active_link('/')" :class="{'active': active_link === '/'}">
                    <router-link to="/"><i class="fa fa-th-large"></i> <span class="nav-label">Početna</span></router-link>
                </li>-->

                <li v-if="permission === 3" @click="change_active_link('skupovi')" :class="{'active': active_link === 'skupovi'}">
                    <router-link to="/skupovi"><i class="fa fa-th-large"></i> <span class="nav-label">Događaji</span></router-link>
                </li>

                <li v-if="permission === 3" :class="{'active': active_link === 'postavke' || sec_level_menu === 'postavke' }">
                    <a @click="open_menu('postavke')" aria-expanded="true"><i class="fa fa-cogs"></i> <span class="nav-label">Postavke</span><span class="fa arrow"></span></a>
                    <ul v-if="sec_level_menu === 'postavke'" :class="{'nav':true, 'nav-second-level':true, 'collapse':true, 'in':sec_level_menu === 'postavke' }">

                        <li @click="change_active_link('postavke/dodatni-sadrzaji', 'postavke' )" :class="{'active': active_link === 'postavke/dodatni-sadrzaji'}">
                            <router-link to="/postavke/dodatni-sadrzaji"> <span class="nav-label"></span>Dodatni sadržaj</router-link>
                        </li>

                        <li @click="change_active_link('postavke/racuni-za-uplate', 'postavke' )" :class="{'active': active_link === 'postavke/racuni-za-uplate'}">
                            <router-link to="/postavke/racuni-za-uplate"> <span class="nav-label"></span>Računi za uplate</router-link>
                        </li>

                        <li v-if="app_owner === 'HKIG'" @click="change_active_link('postavke/vrste-sudionika', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-sudionika'}">
                            <router-link to="/postavke/vrste-sudionika"> <span class="nav-label"></span>Vrste sudionika</router-link>
                        </li>

                        <li @click="change_active_link('postavke/vrste-suorganizatora', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-suorganizatora'}">
                            <router-link to="/postavke/vrste-suorganizatora"> <span class="nav-label"></span>Vrste suorganizatora</router-link>
                        </li>


                    </ul>
                </li>

                <li v-if="permission === 3" @click="change_active_link('korisnici')" :class="{'active': active_link === 'korisnici'}">
                    <router-link to="/korisnici"><i class="fa fa-id-card"></i> <span class="nav-label">Registrirani članovi</span></router-link>
                </li>

                <li v-if="permission === 3" @click="change_active_link('administratori')" :class="{'active': active_link === 'administratori'}">
                  <router-link to="/administratori"><i class="fa fa-users"></i> <span class="nav-label">Administratori</span></router-link>
                </li>


            </ul>

        </div>
    </nav>



</template>

<script>
    import axios from "axios";

    export default {
        name: "Navigation",
        props:[
        ],
        data(){
            return {
                active_link: 'events',
                active_link_old: 'events',
                sec_level_menu: null,
                app_owner:process.env.VUE_APP_APP_OWNER,
                app_name:process.env.VUE_APP_APP_NAME,

                // user
                permission: null
            }
        },
        methods:{
            change_active_link( active_link, sec_level_menu = null ){
                this.sec_level_menu = sec_level_menu
                this.active_link_old = this.active_link;
                this.active_link = active_link
            },
            open_menu( sec_level_menu ){
                if( this.sec_level_menu === sec_level_menu ){
                    this.active_link = this.active_link_old
                    this.sec_level_menu = null
                } else {
                    this.active_link_old = this.active_link;
                    this.active_link = sec_level_menu
                    this.sec_level_menu = sec_level_menu
                }
            },

            /**
             * user
             */
            getUser(){
                let vm = this
                axios.get( process.env.VUE_APP_API_URL + 'user-auth' )
                        .then( function ( response ) {
                            vm.permission = response.data.permission_id

                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )
            }
        },
        mounted() {
            this.getUser()
        }
    }
</script>

<style scoped>

</style>
