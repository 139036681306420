<template>
    <div>
        <div class="vld-parent">
            <loading :active.sync="open_loader"
                     :can-cancel="false"
                     :is-full-page="true">

            </loading>
        </div>
        <forms
                :settings="settings"
                @onFormButtonClick="onFormButtonClick"
                @onInput="onInput"
        >
        </forms>
    </div>
</template>

<script>
import axios from "axios";
import toastr from 'toastr';
import Loading from 'vue-loading-overlay'
import Forms from "@/components/form/Forms";
export default {
    name: "WebinarForma",
    components: { Forms, Loading },
    props:{
        disabled:{
            type: Boolean,
            default: false
        },
        id:{
            default: null
        },
        modal:{
            type: Boolean,
            default: false
        },
        action: {
            type: String
        }
    },
    data(){
        return {
            settings: {},
            errors: {},
            route: '/skupovi/' + this.$route.params.skup_id + '/webinari',
            url: 'webinar',
            data: {},
            open_loader: false,
            skup_id: this.$route.params.skup_id,
            api_provider: null,
            api_skup: null,
        }
    },

    methods: {

        init(){
            let vm = this
            if ( vm.action === 'edit' || vm.action === 'show' ){

                axios.get( process.env.VUE_APP_API_URL + vm.url + '/' + vm.id )
                        .then( function ( response ) {
                            vm.data = response.data
                            vm.$emit( 'returnData', response.data )
                            vm.setupForm()
                        } )
                        .catch( function ( error ) {
                            if(error instanceof Error) {
                                console.log( 'Error: ' . error);
                            } else {
                                console.log( 'Unexpected response: ' . error);
                            }
                        } )

            } else if ( vm.action === 'create' ) {
                vm.data = {}
                vm.setupForm()
            } else {
                return
            }
        },

        setupForm(){

            let vm = this;

            vm.buttons = []

            let button_cancel = {
                id: 'cancel',
                title: 'Odustani',
                type: 'warning',
                action: 'redirect', // request
                config: {
                    url: vm.route + ( vm.action === 'edit' ? ( '/' + vm.id) : '' )
                }
            }

            let button_delete = {
                id: 'delete',
                title: 'Obriši',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'DELETE',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let button_edit = {
                id: 'edit',
                title: 'Uredi',
                type: 'success',
                action: 'redirect',
                config: {
                    url: vm.route +  '/' + vm.id +'/edit'
                }
            }

            let button_save = {
                id: 'save',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + vm.url
                }
            }

            let button_update = {
                id: 'update',
                title: 'Spremi',
                type: 'primary',
                action: 'request',
                config: {
                    method: 'PUT',
                    url: process.env.VUE_APP_API_URL + vm.url + '/' + vm.id
                }
            }

            let buttons = []


            if( vm.action === 'edit' ){
                buttons.push( button_cancel)
                buttons.push( button_update)
            } else if( vm.action === 'show' ) {
                buttons.push( button_cancel )
                buttons.push( button_delete )
                buttons.push( button_edit )
            }
            else if ( vm.action === 'create' ) {
                buttons.push( button_cancel )
                buttons.push( button_save )
            } else {
                return
            }

            let skup_id_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }


            let webinar_api_provider_id_input_value = {
                key: vm.api_provider ? vm.api_provider.id : ( vm.data['webinar_api_provider_id'] ? vm.data['webinar_api_provider_id'] : '' ),
                value: vm.api_provider ? vm.api_provider.title : ( vm.data['webinar_api_provider_id'] ? vm.data['webinar_api_provider']['title'] : '' )
            }

            console.log( webinar_api_provider_id_input_value )

            let webinar_api_provider_id_input = {
                        name: 'webinar_api_provider_id',
                        type: 'select',
                        title: 'API',
                        value: webinar_api_provider_id_input_value,
                        width: 12,
                        error: vm.errors['webinar_api_provider_id'] ? vm.errors['webinar_api_provider_id'] : '',
                        note: '',
                        explanation: '',
                        placeholder: '',
                        required: !vm.disabled,
                        readonly: vm.disabled,
                        disabled: vm.disabled,
                        config: {
                            options: function ( ) {
                                let model = this

                                if( vm.action === 'show'){
                                    model.options = []
                                    return
                                }
                                vm.open_loader = true;
                                axios.get( process.env.VUE_APP_API_URL +  'webinar-api-provider' )
                                        .then( function ( response ) {
                                            let tmp = [];
                                            response.data.data.forEach( function ( item ) {
                                                if( item.active )
                                                    tmp.push({
                                                        key: item.id,
                                                        value: item.title,
                                                        name: item.name,
                                                    } );
                                            } );
                                            model.options = tmp
                                            vm.open_loader = false;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                            vm.open_loader = false
                                        } )
                            },
                            multiple: false,
                            title_width: 1,
                            input_width: 11
                        }
                    }

            let webinar_api_id_input_value = {}
            if( vm.action === 'show' ){
                webinar_api_id_input_value = {
                    key: vm.data['webinar_api_id'] ? vm.data['webinar_api_id'] : '',
                    value: vm.data['webinar_api_id'] ? vm.data['webinar_api_provider']['title'] : ''
                }
            } else {
                if( vm.api_skup ){
                    webinar_api_id_input_value = {
                        key: vm.api_skup.api_id ? vm.api_skup.api_id : '',
                        value: vm.api_skup.api_id ? vm.api_skup.naziv : ''
                    }
                }
            }
            let webinar_api_id_input = {
                name: 'webinar_api_id',
                type: 'select',
                title: 'API događaj',
                value: webinar_api_id_input_value,
                width: 12,
                error: vm.errors['webinar_api_id'] ? vm.errors['webinar_api_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }
                        vm.open_loader = true
                        axios.get( process.env.VUE_APP_API_URL + 'webinar-api/' + vm.api_provider.name )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.api_id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                    vm.open_loader = false
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                    vm.open_loader = false
                                } )
                    },
                    multiple: false,
                    title_width: 1,
                    input_width: 11
                }
            }

            let url_input = {
                name: 'url',
                type: 'input',
                title: 'URL',
                value: vm.api_skup ? vm.api_skup.url : ( vm.data['url'] ? vm.data['url'] : ''),
                width: 12,
                error: vm.errors['url'] ? vm.errors['url'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let skup_tema_id_input = {
                name: 'skup_tema_id',
                type: 'select',
                title: 'Tema',
                value: {
                    key: vm.data['skup_tema_id'] ? vm.data['skup_tema_id'] : '',
                    value: vm.data['skup_tema_id'] ? vm.data['skupovi_teme']['naziv'] : ''
                },
                width: 12,
                error: vm.errors['skup_tema_id'] ? vm.errors['skup_tema_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }
                        axios.get( process.env.VUE_APP_API_URL +  'skup-tema', {
                            params: {
                                filters: {
                                    skup_id: vm.skup_id
                                }
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    multiple: false,
                    title_width: 1,
                    input_width: 11
                }
            }

            let naziv_input = {
                name: 'naziv',
                type: 'input',
                title: 'Naziv',
                value: vm.api_skup ? vm.api_skup.naziv : ( vm.data['naziv'] ? vm.data['naziv'] : '' ),
                width: 12,
                error: vm.errors['naziv'] ? vm.errors['naziv'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let opis_input = {
                name: 'opis',
                type: 'editor',
                title: 'Opis',
                value: vm.api_skup ? vm.api_skup.opis : ( vm.data['opis'] ? vm.data['opis'] : '' ),
                width: 12,
                error: vm.errors['opsi'] ? vm.errors['opsi'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    title_width: 1,
                    input_width: 11
                }
            }

            let datum_od_input = {
                name: 'datum_od',
                type: 'datepicker',
                title: 'Početak događaja',
                value: vm.api_skup ? vm.api_skup.datum_od : ( vm.data['datum_od'] ? vm.data['datum_od'] : '' ),
                width: 3,
                error: vm.errors['datum_od'] ? vm.errors['datum_od'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime',
                    title_width: 4,
                    input_width: 8
                }
            }

            let datum_do_input = {
                name: 'datum_do',
                type: 'datepicker',
                title: 'Završetak događaja',
                value: vm.data['datum_do'] ? vm.data['datum_do'] : '',
                width: 3,
                error: vm.errors['datum_do'] ? vm.errors['datum_do'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    mode:'dateTime',
                    title_width: 4,
                    input_width: 8
                }
            }

            let webinar_permission_id_input = {
                name: 'webinar_permission_id',
                type: 'select',
                title: 'Dozvala',
                value: {
                    key: vm.data['webinar_permission_id'] ? vm.data['webinar_permission_id'] : 1,
                    value: vm.data['webinar_permission_id'] ? vm.data['webinar_permissions']['naziv'] : 'Registrirani korisnici ( sa prijavom na skup )'
                },
                width: 6,
                error: vm.errors['webinar_permission_id'] ? vm.errors['webinar_permission_id'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: false,
                readonly: vm.disabled,
                disabled: vm.disabled,
                config: {
                    options: function ( ) {
                        let model = this

                        if( vm.action === 'show'){
                            model.options = []
                            return
                        }
                        axios.get( process.env.VUE_APP_API_URL +  'webinar-parmission', {
                            params: {
                                order: 'asc'
                            }
                        } )
                                .then( function ( response ) {
                                    let tmp = [];
                                    response.data.data.forEach( function ( item ) {
                                        tmp.push({
                                            key: item.id,
                                            value: item.naziv
                                        } );
                                    } );
                                    model.options = tmp
                                } )
                                .catch( function ( error ) {
                                    if(error instanceof Error) {
                                        console.log( 'Error: ' . error);
                                    } else {
                                        console.log( 'Unexpected response: ' . error);
                                    }
                                } )
                    },
                    multiple: false,
                    title_width: 1,
                    input_width: 11
                }
            }

            let inputs = [];

            inputs.push( skup_id_input )
            inputs.push( webinar_api_provider_id_input )
            if( vm.api_provider && vm.action !== 'show' ){
                inputs.push( webinar_api_id_input )
            }
            if( vm.api_skup || vm.action === 'show' || vm.action === 'edit' ){
                inputs.push( url_input )
                inputs.push( skup_tema_id_input )
                inputs.push( naziv_input )
                inputs.push( opis_input )
                inputs.push( datum_od_input )
                inputs.push( datum_do_input )
                inputs.push( webinar_permission_id_input )
            }



            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on input return data
         */
        onInput( name, value ){
            console.log( name, value )
            let vm = this
            if( name === 'webinar_api_provider_id' ){
                if( value ){
                    vm.open_loader = true
                    axios.get( process.env.VUE_APP_API_URL + 'webinar-api-provider/' + value )
                            .then( function ( response ) {
                                vm.api_provider = response.data
                                vm.api_skup = null
                                vm.setupForm()
                                vm.open_loader = false
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                                vm.open_loader = false
                            } )
                } else {
                    vm.api_provider = null
                    vm.api_skup = null
                }
            } else if( name === 'webinar_api_id' ){
                if( value ){
                    vm.open_loader = true
                    axios.get( process.env.VUE_APP_API_URL + 'webinar-api/' + vm.api_provider.name + '/' + value )
                            .then( function ( response ) {
                                vm.api_skup = response.data
                                vm.setupForm()
                                vm.open_loader = false
                            } )
                            .catch( function ( error ) {
                                if(error instanceof Error) {
                                    console.log( 'Error: ' . error);
                                } else {
                                    console.log( 'Unexpected response: ' . error);
                                }
                                vm.open_loader = false
                            } )
                } else {
                    vm.api_skup = null;
                }
            }
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){

            if( id === 'save' || id === 'update' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    else if ( id == 'update' )
                        toastr.success( 'Uspješno ažurirano!' )
                    this.$router.push({ path: this.route + '/' + data.data.id } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            } else if( id == 'delete' ){
                toastr.error( 'Uspješno obrisano!' )
                this.$router.push({ path: this.route } )
            }

        },
    },

    mounted() {
        this.init()
    }
}
</script>

<style scoped>

</style>